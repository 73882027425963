.fx-activity-history-item-container {
  display: flex;
  flex-direction: row;
  border-bottom:3px dotted #ffffff;
  width:100%;
  color:white;
  margin-bottom:8px;
  min-height:50px;
  padding-top:8px;
  padding-bottom:8px;
  column-gap:8px;
}

.fx-activity-history-item-tag-portion {
  display: flex;
  flex-direction: column;
  width: 200px;
  flex-grow: 0;
  align-items: flex-end; /* Align items to the right */
  justify-content: flex-start; /* Align items to the top */
}

.fx-activity-history-item-total-message-portion {
  display: flex;
  flex-direction: column;
  flex-grow:1;
}

.fx-comp-history-item-tag {
  background:#2c2c2c;
  padding:8px;
  border-radius:4px;
  font-size:16px;
  width:fit-content;
}



.fx-activity-history-item-user-and-status {
  display:flex;
  flex-direction:row;
}

.fx-comp-history-item-tag-type-fluxity-status-dev {
  color:#00FF00;
}

.fx-comp-history-item-tag-type-fluxity-status-stakeholder {
  color:#FFFF00;
}

.fx-comp-history-item-tag-type-fluxity-status-ba {
  color:#00FFFF;
}

.fx-comp-history-item-tag-type-fluxity-status-ux {
  color:#FF00FF;
}

.fx-comp-history-item-tag-type-fluxity-status-qa {
  color:#FFA500;
}

.fx-comp-history-item-tag-type-fluxity-status-project-manager {
  color:#4169E1;
}

.fx-comp-history-item-tag-type-fluxity-status-dev-ops {
  color:#D3A3D3;
}




/*
.fx-activity-history-item {
    display:flex;
    flex-direction: row;
}

.fx-activity-history-item {
    margin-bottom: 16px;
    opacity: 1;
    transform: translateX(0);
    transition: transform 0.5s ease, opacity 0.5s ease;
  }
  
  .fx-activity-history-item.new-item {
    transform: translateX(100%);
    opacity: 0;
    animation: slide-in 0.5s forwards;
  }

  .fx-comp-activity-history-item-message-stack {
    display:flex;
    flex-direction:column;
  }

  .fx-comp-activity-history-item-message-stack-message {
    margin-top:5px;
    margin-bottom:5px;
    border:1px solid #f1f1f1;
    border-radius:4px;
    padding:5px;
  }

  .fx-comp-activity-history-item-message-stack-date {
    font-size:10px;
    color:gray;
  }

  .fx-comp-activity-history-item-message-stack-source {
    display:flex;
    width:fit-content;
    background:var(--uxc-color-blue-dark);
    color:white;
    border-radius:4px;
    font-size:12px;
    padding:5px;
  }

  .fx-comp-activity-history-item-avatar {
    padding-top:35px;
  }
  
  @keyframes slide-in {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

*/