.fx-comp-hyperlogic-dropzone-wrapper {
    background-color: white;
    outline: 2px dashed #FFFFFF;
    outline-offset: 2px;
    margin-bottom:8px;
    margin-top:8px;
    color:black;
    border-radius:4px;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding:8px;
}

.fx-comp-hyperlogic-dropzone-list-item {
    display:flex;
    flex-direction:row;
    width:100%;
    padding:8px;
    border-radius:4px;
    border:1px solid #FFFFFF;
    box-sizing:border-box;
}

.research-elements-list {
    list-style: none;
}

.research-elements-list-item-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
}

.research-elements-list-item-text {
    display: flex;
    flex-direction: column;
    flex-grow:1;
}

.research-elements-list-item-icons {
    flex-grow:0;
}