.fx-comp-hyperlogic-dashboard-dash-header-wrapper {
  display: flex;
  flex-direction: row;
}

.fx-comp-hyperlogic-dashboard-dash-heading {
    display: flex;
    width:20%;
    padding:8px;
    background-color: #65558F;
    color:white;
}

.fx-comp-hyperlogic-dashboard-dash-columns-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.fx-comp-hyperlogic-dashboard-dash-column {
    display: flex;
    width:20%;
    padding:8px;
}

.fx-comp-hyperlogic-dash-card-drag-handle-icon-right {
    display: flex;
    flex-direction: row-reverse;
}