@import '../CSSLibrary/UXMGRVariables.css';

.uxmgr-comp-pagetempmainsidenav-sidnav-outer {
    border:1px solid #f1f1f1;
}
.uxf-comp-sidenav-list {
    margin-right:16px !important;
}

.uxmgr-comp-pagetempmainsidenav-list-leveltwo {
    padding-left:16px !important;
}

.uxmgr-comp-pagetempmainsidenav-list-levelthree {
    padding-left:32px !important;
}
.uxi-comp-sidenav-hidden {
    display: none;
}
.uxi-comp-sidenav-reset-test-data-link {
    color:#1976d2;
    text-decoration:underline;
    cursor:pointer;
}
.uxi-com-sidenav-no-padding {
    padding:0px !important;
}

.fx-comp-side-nav-collapse-container {
    background-color: white;
    border-radius:var(--border-radius-default);
    width:100%;
}
.fx-comp-side-nav-master-link-primary-group-item {
    margin-bottom:var(--padding-med) !important;
    border-radius:4px !important;
    padding:var(--padding-med) !important;
    border:1px solid #292445;
    background:white !important;
}
.fx-comp-side-nav-master-link-primary-group-item.nested {
    background:transparent !important;
    padding:0px !important;
}
.fx-comp-side-nav-master-link-primary-group-item a {
    text-decoration: none !important;
}

.fx-comp-side-nav-master-link-primary-group-item-inner {
    color:#292445;
    
}

.fx-comp-side-nav-master-playbook-header-container {
    border-top:1px solid #ffffff;
    border-bottom:1px solid #ffffff;
    color:#ffffff;
    display:flex;
    flex-direction:row;
}

.fx-comp-side-nav-master-playbook-header-item-name {
    flex-grow:1;
}

.fx-comp-side-nav-master-playbook-header-item-status {
    flex-grow:0;
    padding-top:22px;
}

.fx-comp-side-nav-master-playbook-context-container [role="tablist"] button[aria-selected="false"] {
    color:#ffffff;
}

.fx-comp-side-nav-master-playbook-context-container [role="tablist"] button[aria-selected="true"] {
    color: var(--uxc-color-dark-orange);
    border-bottom: 2px solid var(--uxc-color-dark-orange);
}

.fx-comp-side-nav-master-playbook-back-container {
    display:flex;
    flex-direction:row;
    padding-top:var(--padding-med);
    padding-bottom:var(--padding-med);
}
.fx-comp-side-nav-master-playbook-back-item-button {
    display:flex;
    flex-direction: row;
    flex-grow:1;
    cursor:pointer;
}
.fx-comp-side-nav-master-playbook-back-item-notifications {
    display:flex;
    flex-grow:0;
}

.fx-comp-side-nav-master-link-single-dark {
    color:white;
    text-decoration:none;
}