@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-web-footer {
  background-color: #000000;
  padding: 20px;
  color: white;
}

.fx-comp-web-footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fx-comp-web-footer-logo img {
  height: 50px;
}

.fx-comp-web-footer-contact {
  display: flex;
  justify-content: flex-end;
}

.fx-comp-web-footer button {
    background-color:#93F3FD;
    color:var(--uxc-color-blue-dark);
}

.fx-comp-web-footer button:hover {
    background-color:#ffffff;
    color:var(--uxc-color-blue-dark);
}