@import '../CSSLibrary/UXMGRVariables.css';

.fx-page-start-unauthenticated-outer-container {
    display: flex;
    flex-direction: column;
}

.fx-page-start-unauthenticated-inner-container {
    display: flex;
    width:100%;
    margin: 0 auto;
    flex-direction: column;
}

.fx-page-start-unauthenticated-inner-container h1 {
    display: flex;
    margin: 0 auto;
}

.fx-page-auth-login-login-form-wrap .fx-global-form-row, .fx-page-auth-login-register-form-wrap .fx-global-form-row {
    width: 100%;
    display: flex;
}
.fx-global-form-row.fx-page-auth-login-form-names {
    display: flex;
    flex-direction: row;
    column-gap: 16px;
}

.fx-page-start-hr {
    border: 1px solid #f1f1f1;
    margin: 25px;
}

.fx-page-start-login-wrap {
    display: flex;
}

.fx-page-start-learn-more-wrap {
    display: flex;
    flex-direction: column;
}

.fx-page-start-login-wrap Button {
    margin: 0 auto;
}

.fx-page-start-intro-wrap {
    text-align: left;
}

.fx-page-start-heading-wrap {
    display: flex;
    justify-content: center;
}

.fx-page-start-login-splash-wrap {
    display: flex;
    background-image: url('../images/fx-start-splash.png');
    background-size: cover;
    background-position: center;
    text-align:center;
    flex-direction: column;
    position: relative;
    padding-top:150px;
    padding-bottom:150px;
    line-height:2;
    background-repeat:no-repeat;
}

.fx-page-start-login-splash-wrap::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.fx-page-start-login-splash-wrap > * {
    position: relative;
    z-index: 2;
}



.fx-page-start-unauthenticated-header-login-item {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    column-gap:16px;
}
.fx-page-start-marquee-text-white {
    font-size:64px;
    color:white;
}

.fx-page-start-marquee-text-white-lg {
    font-size:96px;
    color:white;
}

.fx-page-start-marquee-text-teal {
    font-size:48px;
    color:#93F3FD;
}

.fx-page-start-marquee-buttons {
    display:flex;
    flex-direction:row;
    column-gap:16px;
    margin: 0 auto;
    margin-top:20px;
}
.fx-page-start-marquee-buttons button, .fx-page-start-unauthenticated-header-login-item button, .fx-page-start-feature-paragraph button {
    background-color:#93F3FD;
    color:var(--uxc-color-blue-dark);
}

.fx-page-start-marquee-buttons button:hover, .fx-page-start-unauthenticated-header-login-item button:hover, .fx-page-start-feature-paragraph button:hover {
    background-color:#FFFFFF;
    color:var(--uxc-color-blue-dark);
}

.fx-page-start-content-block-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 24px;
}

.fx-page-start-content-block-container-inner {
    max-width:75%;
    margin:0 auto;
    width:-webkit-fill-available;
}

.fx-page-start-content-block-container-inner h2 {
    font-size:48px;
    font-weight:normal;
}

.fx-page-start-content-block-container-inner h3 {
    font-size:32px;
    text-align: left;
}

.fx-page-start-content-block-container-inner p {
    font-size:32px;
}

.fx-page-start-content-block-container-gray {
    background-color: #D9D9D9;
}

.fx-page-start-content-block-container-gray h2 {
    color:#000000;
}

.fx-page-start-flex-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.fx-page-start-flex-item-image {
    flex: 0 0 40%;
}

.fx-page-start-flex-item-text {
    flex: 1;
}

.fx-page-start-flex-item-text h3 {
    margin-top: 0;
    text-align: left;
}

.fx-page-start-feature-paragraph {
    font-size:32px;
}

.fx-page-start-flex-item-text p {
    margin-bottom: 20px;
}

p.fx-page-start-feature-bullet, ul.fx-page-investor-bullets-list {
    font-size:20px;
}

.fx-page-start-flex-container-ideation-completion-section, .fx-page-start-flex-container-interrupt-section, .fx-page-start-flex-container-closing-section {
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.fx-page-start-flex-item-ideation-completion, .fx-page-start-flex-item-interrupt-section, .fx-page-start-flex-item-closing-section {
    display:flex;
    flex-direction: column;
    flex-grow:1;
    text-align:left !important;
}

.fx-page-start-section-outer-ideation-completion {
    color:white;
    background-image: url('../images/fx-page-start-feature-ideation-to-completion-bg.png');
    background-size: cover;
    background-position: center;
    margin:0;
    position: relative;
}

.fx-page-start-section-outer-ideation-completion::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.fx-page-start-section-outer-ideation-completion > * {
    position: relative;
    z-index: 2;
}

.fx-page-start-flex-container-interrupt-section img {
    height:250px;
}

.fx-page-start-content-block-container-outer-closing-section {
    background-color:var(--uxc-color-blue-dark);
    margin:0;
    color:white;
}

.fx-page-investor-organize-and-execute-inner-container {
    display:flex;
    flex-direction:row;
}

.fx-page-investor-organize-and-execute-item-wrap {
    width:50%;
    flex-direction:column;
    display:flex;
}

.fx-page-investor-bullets-list {
    display:flex;
    list-style: none;
    flex-direction: column;
}

.fx-page-investor-button-center {
    display: flex;
    margin:0 auto;
}

.fx-page-start-content-block-container-blue-form {
    background-color:var(--uxc-color-blue-dark);
    color:white;
}

.fx-page-start-flex-predict-prevent h3 {
    margin:0px;
}

.fx-page-start-content-block-main-one {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.fx-page-start-content-block-main-col {
    flex: 1 1 calc(50% - 16px); /* Two columns with spacing */
    box-sizing: border-box;
}

@media only screen and (max-width: 599px) {
    .fx-page-start-content-block-main-col {
        background-color: red;
    }
}

.fx-page-start-marquee-text-white,
.fx-page-start-marquee-text-teal,
.fx-page-start-marquee-text-white-lg {
  text-align: center;
}

@media only screen and (max-width: 599px) {
  .fx-page-start-marquee-text-white,
  .fx-page-start-marquee-text-teal,
  .fx-page-start-marquee-text-white-lg {
    font-size: inherit; /* Material-UI handles responsive typography */
  }
}

@media only screen and (max-width: 599px) {
  .fx-page-start-marquee-text-white {
    font-size: 3.75rem !important;
  }
}

.fx-page-start-test {
    font-size:50px;
}

@media only screen and (max-width: 599px) {
    .fx-page-start-test {
      font-size: 24px !important; /* Ensure this overrides other styles */
    }
  }