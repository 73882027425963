.milestone-list-container {
    overflow-x: auto;
    white-space: nowrap;
}

.milestone-list {
    display: flex;
    flex-direction: row;
}

.milestone-item {
    display: flex;
    width: 350px;
    margin: 16px;
    text-align: center;
    flex-direction: column;
    height:100vh;
    border:1px solid #f1f1f1;
}

.milestone-box {
    position: relative;
    background: white;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:16px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border:1px solid #cccccc;
}

.milestone-box-content {
    /* No rotation needed */
}

.milestone-details {
    background: #f1f1f1;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
}

.milestone-header {
    display:flex;
    flex-direction: column;
}
