.fx-comp-journey-image-block-header-container {
    display:flex;
    flex-direction: row;
}

.fx-comp-journey-image-block-header-name-item {
    display:flex;
    flex-grow:1;
}

.fx-comp-journey-image-block-header-icon-item {
    flex-grow:0;
    display:flex;
}