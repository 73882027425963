@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-user-persona-avatar {
    width:30px;
    height:30px;
}

.fx-comp-user-persona-avatar-lg {
    width:100px;
    height:100px;
}