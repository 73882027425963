@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-activity-panel-status-intro-pill-outer {
    padding:var(--padding-med);
    display:flex;
    flex-direction: row;
}

.fx-request-status-outer {
    display:flex;
    flex-direction: row;
    align-items: center;
    padding:var(--padding-med);
}

.fx-request-status-outer button {
    background-color: white;
}

.fx-comp-activity-panel-wrapper [role="tablist"] button[aria-selected="false"] {
    color:white;
}

.fx-comp-activity-panel-wrapper [role="tablist"] button[aria-selected="true"] {
    color: var(--uxc-color-dark-orange);
    border-bottom: 2px solid var(--uxc-color-dark-orange);
}