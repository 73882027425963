@import '../CSSLibrary/UXMGRVariables.css';

.fx-crud-modal-objective-view-row {
    display: flex;
    flex-direction: row;
}
.fx-crud-modal-objective-view-row > div {
    margin-right:var(--padding-lg);
}

.fx-objective-detail-slider-wrap {
    width:200px;
}

.fx-comp-crud-form-objective-select-anchor-wrap {
    display:flex;
    flex-direction: column;
}

.fx-comp-crud-form-objective-select-anchor-wrap label {
    font-size:16px;
    font-family:'Open Sans';
}
.fx-comp-crud-form-objective-select-anchor-wrap select {
    height:56px;
    font-size:16px;
    font-family:'Open Sans';
    border-color:#cccccc;
    border-radius:4px;
}

.fx-crud-form-darkBG .MuiTextField-root input, .fx-crud-form-darkBG .MuiTextField-root .MuiInputBase-root {
    background-color: #fff;
    color: #000;
}

.fx-crud-form-darkBG .MuiInputBase-input {
    color: #000;
}

.fx-crud-form-darkBG .MuiInputLabel-root {
    color: #fff;
    position: static;
    transform: none;
}

.fx-crud-form-darkBG .MuiFormLabel-root {
    color: #fff;
}

.fx-crud-form-darkBG .MuiInputBase-input::placeholder {
    color: #888;
}

.fx-crud-form-darkBG .MuiSelect-root select {
    background-color: #fff;
    color: #000;
}

.fx-crud-form-darkBG .MuiSelect-icon {
    color: #000;
}

.fx-crud-form-darkBG .CompModalButtons button {
    background-color: #fff;
    color: #000;
}

#new-milestone, #select-template-select {
    height:50px;
    border-radius:4px;
}

button.fx-objective-form-button {
    background-color: white !important;
}