.fx-comp-page-temp-wizard-container {
    background-image: url('../images/codioful-formerly-gradienta-OzfD79w8ptA-unsplash.jpg');
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    color:white;
    padding-bottom:40px;
}

.fx-comp-page-temp-wizard-container input {
    background:white;
}

.fx-comp-page-temp-wizard-container label {
    color: white; /* Set the label color to white */
}

.fx-comp-page-temp-wizard-outer {
    margin:0 auto;
}

.fx-comp-page-temp-wizard-outer.max-width-25 {
    width:25%;
}

.fx-comp-page-temp-wizard-outer.max-width-40 {
    width:40%;
}

.fx-comp-page-temp-wizard-inner {
    border: 2px dashed #ffffff;
    padding:16px;
    border-radius:4px;
}

/* Ensure tab headers are white and the active tab border is white */
.MuiTab-root {
    color: white;
}

.MuiTabs-indicator {
    background-color: white;
}

.fx-comp-page-temp-wizard-logo {
    margin:0 auto;
    width:fit-content;
    padding-top:20px;
    padding-bottom:20px;
}