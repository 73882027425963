.fx-page-start-flex-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.fx-page-start-flex-item-text {
  flex: 1;
  max-width: 50%;
}

.fx-page-start-flex-item-form {
  flex: 1;
  max-width: 100%;
}

.fx-page-start-feature-socials {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.fx-page-investor-organize-and-execute-item-wrap {
    width:100%;
    max-width: 100%;
}

@media only screen and (max-width: 599px) {
  .fx-page-start-marquee-text-white {
    font-size: 3.75rem !important;
  }
}