.fx-comp-hyperlogic-dash-card-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 200px;
    border-radius: 8px;
    background-color: #FFF8F1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 8px;
    border:1px solid #FFA84C;
    color:#9CA3AF;
    padding:16px;
    box-sizing: border-box;
}

.fx-comp-hyperlogic-dash-card-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    color:#000000;
    padding:8px;
}

.fx-comp-hyperlogic-dash-card-content{
    display: flex;
    flex-direction: column;
    padding:8px;
}

.fx-comp-hyperlogic-dashboard-dash-column {
    display: flex;
    flex-direction: column;
}

.fx-comp-hyperlogic-dash-card-drag-handle {
    display:flex;
    flex-direction:row;
    justify-content: space-between;
}