@import '../CSSLibrary/UXMGRVariables.css';

.fx-crud-form-darkBG .MuiTextField-root input, .fx-crud-form-darkBG .MuiTextField-root .MuiInputBase-root {
    background-color: #fff;
    color: #000;
}

.fx-crud-form-darkBG .MuiInputBase-input {
    color: #000;
}

.fx-crud-form-darkBG .MuiInputLabel-root {
    color: #fff;
    position: static;
    transform: none;
}

.fx-crud-form-darkBG .MuiFormLabel-root {
    color: #fff;
}

.fx-crud-form-darkBG .MuiInputBase-input::placeholder {
    color: #888;
}

.fx-crud-form-darkBG .MuiSelect-root select {
    background-color: #fff;
    color: #000;
}

.fx-crud-form-darkBG .MuiSelect-icon {
    color: #000;
}

.fx-crud-form-darkBG .CompModalButtons button {
    background-color: #fff;
    color: #000;
}

button.fx-feedback-form-button {
    background:white;
    margin-top:8px;
}
