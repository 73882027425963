@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-side-nav-objective-list-create-objective {
    border-top:1px solid #ffffff;
    border-bottom:1px solid #ffffff;
    margin-top:var(--padding-med);  
}

.fx-comp-side-nav-objective-list-item-top-row {
    display:flex;
    flex-direction:row;
}
.fx-comp-side-nav-objective-list-item-top-row-name {
    display:flex;
    flex-grow:1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%; /* Ensure the container width is 100% */
}
.fx-comp-side-nav-objective-list-item-top-row-link {
    display:flex;
    flex-grow:0;
}
.fx-comp-side-nav-objective-list-item-top-row-description {
    color:#757575;
}