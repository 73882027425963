@import '../CSSLibrary/UXMGRVariables.css';
@import '../CSSLibrary/UXMGRMaterialOverrides.css';

.select-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.select-container > div {
  flex: 1;
  min-width: 150px;
}

.fx-crud-form-darkBG .MuiTextField-root input, .fx-crud-form-darkBG .MuiTextField-root .MuiInputBase-root {
  background-color: #fff;
  color: #000;
}

.fx-crud-form-darkBG .MuiInputBase-input {
  color: #000;
}

.fx-crud-form-darkBG .MuiInputLabel-root {
  color: #fff;
  position: static;
  transform: none;
}

.fx-crud-form-darkBG .MuiFormLabel-root {
  color: #fff;
}

.fx-crud-form-darkBG .MuiInputBase-input::placeholder {
  color: #888;
}

.fx-crud-form-darkBG .MuiSelect-root select {
  background-color: #fff;
  color: #000;
}

.fx-crud-form-darkBG .MuiSelect-icon {
  color: #000;
}

.fx-crud-form-darkBG .CompModalButtons button {
  background-color: #fff;
  color: #000;
}

button.fx-component-form-button {
  background:white;
  margin-top:8px;
}

.fx-crud-form-design-session-participant-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  list-style: none;
}

.fx-crud-form-design-session-participant {
  display:flex;
  flex-direction: row;
}

