@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-node-breakdown-objective-name-container {
    display: flex;
    flex-direction: row;
    padding: 5px;
    border-radius: var(--border-radius-default);
    background-color: white;
    border-color:1px solid gray;
    justify-content:space-between;
}
.fx-comp-node-breakdown-objective-name-item-name {
    display:flex;
    flex-grow:1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100px;
}

.fx-comp-node-breakdown-objective-name-item-tools {
    display:flex;
    flex-grow:0;
    flex-direction:row;
}

.fx-comp-node-breakdown-group-heading {
    text-align: left;
    display: flex;
    flex-direction: row;
    border:1px solid #cccccc;
    border-radius: var(--border-radius-default);
    padding:var(--padding-med);
    margin-bottom:5px;
}
.fx-comp-node-breakdown-group-heading-text {
    display:flex;
    flex-grow:1;
}

.fx-comp-node-breakdown-group-heading-icon {
    display:flex;
    flex-grow:0;
}

.fx-comp-node-breakdown-group-heading-ico-add {
    cursor:pointer;
}
.fx-comp-node-breakdown-add-item-phrase {
    color:#bbbbbb;
    text-align: left;
    cursor: pointer;
}

.fx-comp-node-breakdown-list-item-container {
    display:flex;
    flex-direction: row;
}

.fx-comp-node-breakdown-list-item-text {
    display:flex;
    flex-grow:1;
    flex-direction: row;;
}

.fx-comp-node-breakdown-list-item-actions {
    display:flex;
    flex-grow:0;
    flex-direction: row;;
}

.fx-comp-node-breakdown-collapsible-wrapper {
    margin-bottom:8px;
}