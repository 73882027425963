.fx-page-start-unauthenticated-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--uxc-color-blue-dark);
    padding-left:20px;
    padding-right:20px;
}

.fx-page-start-unauthenticated-header-logo-item {
    flex: 1;
}