@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-collapsible-container-item-count-wrap {
    background:#03205b;
    color:white;
    margin-left:var(--padding-med);
    padding:2px;
    min-width:20px;
    text-align: center;
    border-radius:50px;
}

.fx-comp-collapsible-container-heading-row {
    display:flex;
    flex-direction:row;
    width:100%;
}
.fx-comp-collapsible-container-heading-row-item-heading {
    flex-grow:1;
    display:flex;   
    flex-direction:row;
}
.fx-comp-collapsible-container-heading-row-item-actions {
    flex-grow:0;
    display:flex;
    flex-direction:row;
}