.fx-comp-web-mobile-menu {
  display: flex;
  align-items: center;
}

.fx-comp-web-mobile-menu-icon {
  display: none;
}

.fx-comp-web-desktop-menu {
  display: flex;
}

@media (max-width: 768px) {
  .fx-comp-web-mobile-menu-icon {
    display: flex;
  }
  .fx-comp-web-desktop-menu {
    display: none;
  }
}

.fx-comp-web-mobile-drawer .MuiDrawer-paper {
  width: 250px;
}
