@import '../CSSLibrary/UXMGRVariables.css';

.fx-page-activity-toolbar-container {
    display:flex;
    flex-direction:row;
    column-gap: 16px;
    margin-bottom:16px;
    padding:8px;
    border-radius:4px;
    background-color:white;
    color: var(--uxc-color-blue-dark);
}

.fx-page-activity-toolbar-toggleset {
    display:flex;
    flex-direction: column;
}

.fx-page-activity-main-container {
    background-color: var(--uxc-color-blue-dark);
    padding:32px;
    border-radius:12px;
    color:white;
}

/* Toggle button styles */
.MuiToggleButton-root {
    color: white;
    border-color: white;
}

.MuiToggleButton-root.Mui-selected {
    background-color: #f1f1f1;
    color: #292444;
    border-color: #292444;
}